import {graphql, PageProps} from "gatsby";
import React from "react";
import {createNavItems} from "../utils/nav-items-util";
import PageWrapper from "../components/PageWrapper";
import Layout from "../components/Layout";
import SliceZone from "./slices/SliceZone";
import SEO from "../components/seo";

type Props = {
  allPrismicPosts: {
    nodes: {
      uid: string
      url: string
      tags: string[]
      first_publication_date: string
      last_publication_date: string
      data: {
        title: {
          text: string
        }
        summary: {
          text: string
        }
        cover: {
          url: string
        }
        body: {
          slice_type: string
        }[]
      }
    }[]
  }
}

const BlogPost: React.FC<PageProps<Props>> = ({data: {allPrismicPosts}, location}) => {
  const post = allPrismicPosts.nodes[0];
  const tag = post.tags[0] || "";
  return (
    <Layout navItems={createNavItems({current: "blog"})}>
      <PageWrapper className={"flex flex-col items-center mt-8 mb-16"}>
        <SEO
          title={post.data.title.text}
          description={post.data.summary.text}
          pathname={location.pathname}
          image={post.data.cover?.url}
        />
        <div className="pt-8 md:pt-24">
          <div className="font-extrabold text-xs text-center">
            <span className="text-gray-400">{"May 25, 2021".toUpperCase()}</span>
            <span className="text-gray-400 px-2">•</span>
            <span className="text-gray-400">{"2 min read".toUpperCase()}</span>
            <span className="text-gray-400 px-2">•</span>
            <span className="text-red-500">{tag.toUpperCase()}</span>
          </div>
          <h1 className="font-extrabold break-normal text-center text-2xl md:text-4xl mt-2 mb-4">
            {post.data.title.text}
          </h1>
        </div>
        <div className="max-w-screen-sm mt-8 mb-8">
          <img src={post.data.cover.url} alt="alt" className="w-full"/>
        </div>
        <div className="mt-8 max-w-screen-sm prose">
          <SliceZone slices={post.data.body as any[]}/>
        </div>
      </PageWrapper>
    </Layout>
  );
}

export default BlogPost;

export const query = graphql`
  query PostByUid($uid: String!) {
    allPrismicPosts(filter: {uid: {eq: $uid}}) {
      nodes {
        uid
        url
        tags
        first_publication_date
        last_publication_date
        data {
          title {
            text
          }
          summary {
            text
          }
          cover {
            url
            alt
          }
          body {
            __typename
            ... on PrismicPostsBodyQuote {
              id
              slice_type
              primary {
                quote {
                  html
                }
              }              
            }
            ... on PrismicPostsBodyCodeBlock {
              id
              slice_type
              primary {
                code_block {
                  html
                }
              }
              slice_label
            }
            ... on PrismicPostsBodyImageGallery {
              id
              slice_type
              items {
                image_captions {
                  text
                }
                gallery_image {
                  alt
                  url
                }
              }
              primary {
                name_of_the_gallery {
                  html
                }
              }
            }
            ... on PrismicPostsBodyText {
              id
              slice_type
              primary {
                text {
                  html
                }
              }
            }
            ... on PrismicPostsBodySingleImage {
              id
              slice_type
              primary {
                image {
                  url
                  alt
                }
              }              
            }
          }
        }
      }
    }
  }
`
